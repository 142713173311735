<template>
  <div id="DefaultReservations">
    <card>
      <div id="grid-kpis">
        <qs-kpi qlik-id="vYvqBLC" />
        <qs-kpi qlik-id="YnemCaE" />
        <qs-kpi qlik-id="TSSKph" />
        <qs-kpi qlik-id="QtyJWDu" />
      </div>
    </card>

    <QsCard :qlik-ids="['HycW']" />

    <QsCard
      :qlik-ids="['TXLafC']"
      style="grid-column: 1 / span 2; grid-row: 2 / span 1"
    />
  </div>
</template>

<script>
// UNUSED, but as seen with @Jean we keep it for now
import Card from "@/components/Card";
import QsCard from "@/components/Qlik/QsCard";
import QsKpi from "@/components/Qlik/QsKpi";

export default { components: { Card, QsCard, QsKpi } };
</script>

<style scoped>
#DefaultReservations {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 300px 400px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}

#grid-kpis {
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
}
</style>
